import "./summarize.scss";
import {useState} from "react";
import {Link} from "react-router-dom";
import toast, { Toaster } from 'react-hot-toast';

const Summary = ()=>{

    const[ytlink,setYtlink] = useState("");
    const[sec1,setSec1] = useState("");
    const[sec2,setSec2] = useState("");
    const[sec3,setSec3] = useState("");

    // if(sum===""){
    //     setSum("1. Ancestors' Adaptation: - Our ancestors were able to reboot their focus, energy, and productivity out of necessity. - You can intentionally harness this ancient adaptation to increase productivity and access Flow State. 2. Importance of Physical Position: - Your physical position at work can be counterproductive and impact your overall productivity and health. - Changing your physical position can reduce perceived effort and reset fatigue levels, increasing mental stamina and productivity. 3. Setting Up Working Environments: - Set up your main working environment to accommodate sitting, standing, and walking, with a standing desk and a motion board. - Set up three additional working environments with fixed variables to reset perceived effort and induce flow, reducing the struggle phase and increasing productivity. 4. Novelty and Productivity: - Novelty in your working environment is critical in combating fatigue and increasing productivity, even though it may seem paradoxical. - Continuously switching between different environments for deep work blocks can stimulate your mind, reset perceived effort, and increase focus and flow. 5. Taking Breaks: - Taking breaks that boost dopamine instead of depleting it is essential for sustaining productivity throughout the workday. - Understanding how to take breaks effectively can set a new bar for your productivity and overall well-being.")
    // }
    

    const handleData = async()=>{

        toast('your request is in process',
        {
          icon: '😄',
          style: {
            borderRadius: '10px',
            background: '#333',
            color: '#fff',
          },
        }
        );


        let response = await fetch(process.env.REACT_APP_SUMMARIZE_URL,
        {   
            method:"POST",
            body:JSON.stringify({ytlink}),
            headers:{
                "content-type":"application/json"
            }
        })

        response=await response.json();
        // console.log(response.item);
        setSec1(response.item.section1);
        setSec2(response.item.section2);
        setSec3(response.item.section3);
    }

    return(
        <div className="summary">

             <div className="navText">
                 <h2><Link to='/' className="link">AiTubee.com</Link></h2>
                 <div className="smalltext">
                 <span className="highlighttext">summarize any youtube video</span>
                 </div>
             </div>
            <div className="inputcontrollers">
            <input className="input" type="text" onChange={(e)=>setYtlink(e.target.value)} value={ytlink} placeholder="paste your link here"/> 
            <button className="button" onClick={handleData}>Generate Summary</button>
            </div>
            <div className="summarizepara">
                  <div className="section1">
                    <p>{sec1}</p>
                  </div>
                  <div className="section2">
                  <p>{sec2}</p>
                  </div>
                  <div className="section3">
                  <p>{sec3}</p>
                  </div>
            </div>
              <Toaster/>
        </div>
    )
}

export default Summary;