import "./featuresnv.scss";
import title from "./titles.png"
import desc from "./descv.mp4"
import tags from "./tags.png"

const Featuresnv = ()=>{

    return(
        <div className="featuress" id="featurespage">
            <div className="wrappers">
                <div className="heading">
                    <h2>What You'll Get</h2>
                    <div className="progrss"></div>
                </div>

                <div className="titlecontainer">
                    <div className="imgagecontainer">
                        <img src={title} alt="titleimage"/>
                    </div>
                    <div className="textContainer">
                        <h2>More Youtube Algorithm Friendly Titles</h2>
                        <p>Get yourself 3 titles which will get your videos viral . Mainly trained to generate titles which will get your videos more impressions and search rate .</p>
                    </div>
                </div>
                <div className="descriptioncontainer">
                <div className="textContainer">
                        <h2>Get 200-250 words of description</h2>
                        <p>According to Youtube Algorithm , the best description length always reamins around 200-250 words which will include the major keyboards of your videos spoken in inital minutes and used in title. Our tool is mainly designed to generate description by following the rules which will suits the algorithm. </p>
                    </div>
                    <div className="imgagecontainer">
                        <video autoPlay muted  loop src={desc} ></video>
                    </div>
                    
                </div>
                <div className="tagscontainer">
                    <div className="imgagecontainer">
                        <img src={tags} alt="tagsimg" />
                    </div>
                    <div className="textContainer">
                        <h2>Don't have to worry about tags no more</h2>
                        <p>Now , you don't have to think about tags anymore. Aitube.ai is helpful to generate tags which will go best with the title and description of your video. This will help the video to rank on 1-5 top videos on youtube.</p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Featuresnv;