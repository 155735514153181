import React , {useEffect} from "react";
import { useNavigate } from "react-router-dom";

const Protected = (props) =>{
    const {Component} = props;
    const navigate = useNavigate();

    const checkuser = async ()=>{
        let response = await fetch(process.env.REACT_APP_CHECKUSER_URL,{
            credentials:"include" 
        })
        response = await response.json();
        switch(response.result){
            case "unauthorized_user":{
                navigate("/pricing");
                break;
            }

            case "unauthenticated_user":{
                navigate("/");
                break;
            }
            case "authorized_user":{
                navigate("/gen")
                break;
            }
            default:{
                navigate("/")
            }

            
        }


    }
    useEffect(()=>{
        
        checkuser();
    },[])

    
    return(
        <div>
            <Component/>
        </div>
    )

}

export default Protected;