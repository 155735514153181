import "./footer.scss"
import{Link} from "react-router-dom";

const Footer = ()=>{
    return(
        <div className="footer">
            <div className="contact">
                <h3><Link to ="/" className="link">Aitubee.com</Link></h3>
                <h3><Link to = '/terms' className="link">Terms</Link></h3>
                <h3><Link to ='/privacypolicy' className="link"> Privacy Policy</Link></h3>
                <h3><Link to='/contact' className="link">Contact-us</Link></h3>
            </div>



        </div>
    )
}

export default Footer;