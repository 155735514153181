import Footer from "../footer";
import "./privacypolicy.scss";
import{Link} from "react-router-dom";

const PrivacyPolicy = ()=>{
    return(
        <div className="privacypolicycontainer">
            <div className="wrapper">
              <div className="navText">
                  <h2><Link to='/' className="link">AiTubee.com</Link></h2>
               </div>
            <div className="privacypolicy">
                <h3>Privacy Policy</h3>
                <p>Aitubee.com ("Company," "we," or "us") is committed to protecting the privacy of our 
                    users ("you" or "your"). This Privacy Policy explains how we collect, use, and share
                     information about you through our website, https://aitubee.com (the "Website"), 
                     and the services we offer (the "Services"). By using the Website or Services, 
                     you consent to the collection, use, and sharing of your information as described
                      in this Privacy Policy.</p>
            </div>

            <div className="informationwecollect">
                <h3>Information We Collect</h3>
                <p>We collect information you provide to us when you create an account on our Website, including your 
                    email address. We also collect information about your usage of our Services,
                     including the YouTube channels you connect to the Service. 
                     </p>
            </div>

            <div className="useofyourinformation">
                <h3>Use of Your Information</h3>
                <p>We use the information we collect to provide, maintain, and improve our Services, 
                    to communicate with you, and to better understand the demographics of our users. 
                    We may also use the information to send you promotional and marketing materials.</p>
            </div>

            <div className="sharingyourinformation">
                <h3>Sharing of Your Information</h3>
                <p>
                We may share your information with third-party service providers to provide, maintain, 
                and improve our Services, such as Stripe for processing payments. We may also share your 
                information in response to legal requests, such as a subpoena or court order, or in response 
                to a government request. We may also share your information in the event of a merger,
                 acquisition, or sale of all or a portion of our assets.
                </p>
            </div>

            <div className="dataretentionanddeletion">
                <h3>Data Retention and Deletion</h3>
                <p>We retain your information for as long as your account is active or as
                     needed to provide you with our Services. If you wish to delete your entire data,
                      you may send a request to ks745806@gmail.com, 
                    and we will process your request in accordance with applicable laws.</p>
            </div>

            <div className="changestothisprivacypolicy">
                <h3>Changes to this Privacy Policy</h3>
                <p>
                We may update this Privacy Policy from time to time. 
                We will notify you of any changes by posting the new Privacy Policy on this page.
                </p>
            </div>

            <div className="contactus">
                <h3>Contact Us</h3>
                <p>If you have any questions about this Privacy Policy or our 
                    practices related to your information, please contact us at ks745806@gmail.com.</p>
            </div>
            </div>

        <Footer/>
        </div>
    )
}

export default PrivacyPolicy;