import Footer from "../footer";
import "./terms.scss"
import {Link} from "react-router-dom";

const Terms = ()=>{
    return(
        <div className="terms">
            <div className="wrapper">
            <div className="navText">
                  <h2><Link to='/' className="link">AiTubee.com</Link></h2>
               </div>
            <div className="termsofservice">
                <h3>Terms of Service</h3>
                <p>These Terms of Service (the “Terms”) are a legal agreement between you 
                    and Aitubee.com, and they govern your use of the Aitubee.com website and 
                    services (collectively, the “Service”). By accessing or using the 
                    Service, you agree to be bound by these Terms and by the Aitubee.com 
                    Privacy Policy, which is incorporated into these Terms by reference.
                     If you do not agree to these Terms, you may not use the Service.</p>
            </div>

            <div className="accounts">
                <h3>Accounts</h3>
                <p>
                To access certain features of the Service, you must create an account.
                 You agree to provide accurate and complete information when creating your account, 
                 and you agree to keep your account information up-to-date. 
                 You are responsible for all activities that occur under your account, including any 
                 unauthorized activities. If you suspect that someone has gained unauthorized access 
                 to your account, you should notify Morise.ai immediately.
                </p>
            </div>

            <div className="refunds">
                <h3>Refunds</h3>
                <p>Refunds for the Service will be given within 7 days of purchase.</p>
            </div>

            <div className="notransferofaccounts">
                <h3>No Transfer Of Accounts</h3>
                <p>
                Aitubee.com does not allow the transfer of accounts to another party.
                </p>
            </div>

            <div className="commercialuse">
                <h3>Commerical Use</h3>
                <p>Commercial use of the Service is allowed.</p>
            </div>

            <div className="useofcompanylogosandnames">
                <h3>Use of Company Logos and Names</h3>
                <p>By signing up for the Service with a work email address, you grant Aitubee.com 
                    permission to use your company's logo and name on our landing page or in our advertisements 
                    to indicate that your company uses our Service. 
                    This permission is conditional upon your continued use of the Service.</p>
            </div>

            <div className="disclamer">
                <h3>Disclaimer of Warranty and Limitation of Liability</h3>
                <p>
                The Service is provided on an “as is” and “as available” basis. 
                Aitubee.com does not warrant that the Service will be error-free, uninterrupted, or secure, 
                and you use the Service at your own risk. 
                Aitubee.com is not responsible for any legal mishappenings generated by AI.
                </p>
            </div>

            <div className="changestothesetermsofservice">
                <h3>Changes to these Terms of Service</h3>
                <p>Aitubee.com may modify these Terms at any time by posting a revised version on the Service.
                     You agree that your continued use of the Service after the effective date of the revised Terms
                      constitutes your acceptance of the revised Terms.</p>
            </div>

            <div className="contactus">
                <h3>Contact Us</h3>
                <p>If you have any questions about these Terms or the Service, please contact us at hello@aitubee.com</p>
            </div>
            </div>
            <Footer/>
        </div>
       
    )
}

export default Terms;