import './app.scss';

import Checkapi from './components/apitest/api';
import Featuresnv from './components/featuresnv/featuresnv';
import Footer from './components/footer/footer';
import Home from './components/home/home';
import Navbar from './components/navbar/nav';
import {BrowserRouter,Routes,Route} from "react-router-dom";
import Summary from './components/summarize/summarize';
import Pricing from './components/pricing/pricing';
import Terms from './components/footer/Terms/terms';
import Contact from './components/footer/contactus/contactus';
import PrivacyPolicy from './components/footer/privacypolicy/privacypolicy';
import Protected from './components/Protected/protected';
import Howtouse from './components/howtouse/howtouse';



function App() {
  
  return (
    <div>
      <BrowserRouter>
      <Routes>
        <Route path='/' element={<><Navbar/>
          <Home/>
          <Featuresnv/>
          
          <Footer/></>}/>   
          <Route path='/gen' element={<Protected Component={Checkapi}/>}/>
          <Route path='/summarize' element={<Summary/>}/>
          <Route path='/pricing' element={<Pricing/>}/>
          <Route path='terms'element={<Terms/>}/>
          <Route path='/privacypolicy' element={<PrivacyPolicy/>}/>
          <Route path='/contact' element={<Contact/>}/>
          <Route path="/howtouse" element={<Howtouse/>}/>
      </Routes>    
      </BrowserRouter>
    </div>
   
  );
}

export default App;
