import Footer from "../footer";
import "./contactus.scss";
import {Link} from "react-router-dom";

const Contact = ()=>{
    return(
        <div className="contactcontainer">
            <div className="wrapper">
            <div className="navText">
                  <h2><Link to='/' className="link">AiTubee.com</Link></h2>
               </div>
            <div className="contact">
                <h1>Contact</h1>

                <p>You can currently reach out to us for anything (e.g. refunds, queries, requests) 
                    on either of the following options:</p>
            </div>

            <div className="sociallinks">
                <h2>ks745806@gmail.com</h2>
                <h2><Link to ="https://twitter.com/ShakyaKuldeep1" className="link" target="_blank">Twitter(𝕏)</Link></h2>
                <h2><Link to ="https://www.linkedin.com/in/kuldeep-shakya-b0b481253/" className="link" target="_blank">LinkedIn</Link></h2>
            </div>
            </div>
            <Footer/>
        </div>
    )
}

export default Contact;