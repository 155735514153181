import "./howtouse.scss"
import captions from "./assests/captions.png"
import copylink from "./assests/copyurl.png"
import uploaded from "./assests/uploaded.png"
import uploadtype from "./assests/uploadtype.png"
import {Link} from "react-router-dom";


const Howtouse = ()=>{
    return(
        <div className="howtouse">
            <div className="wrapper">
                <div className="mainheading">
                <h2><Link to='/' className="link">AiTubee.com</Link></h2>
                </div>
                <div className="uploadcontainer">
                    <div className="textcontainer">
                        <h3><span>Upload</span> the video</h3>
                        </div>
                        <div className="imagecontainer">
                            <img src = {uploaded} alt="uploaded"/>
                        </div>
                    
                </div>
                <div className="unlistedcontainer">
                    
                    <div className="imagecontainer">
                        <img src={uploadtype} alt="uploadtypei.e.unlisted"/>
                    </div>
                    <div className="textcontainer">
                        <h3>Save the <span>video unlisted</span> once its uploaded</h3>
                    </div>
                </div>
                <div className="checkforcaptions">
                    <div className="textcontainer">
                        <h3>check if <span>captions</span> are available</h3>
                    </div>
                    <div className="imagecontainer">
                        <img src={captions} alt="checkforcaptions"/>
                    </div>
                </div>
                <div className="copylinkcontainer">
                <div className="imagecontainer">
                        <img src={copylink} alt="copytheurl"/>
                    </div>
                    <div className="textcontainer">
                        <h3><span>Copy</span> the Video Url and paste in the <span>App</span></h3>
                    </div>
                    
                </div>         
            </div>
        </div>
    )
}

export default Howtouse;