import "./home.scss"
import video from "./aitubeefront.mp4"
import { useState , useEffect } from "react"
import { useNavigate } from "react-router-dom";
import toast, { Toaster } from 'react-hot-toast';


const Home = () => {
    const navigate = useNavigate();
    const[error,setError] = useState(false);   
    const setlogin = async ()=>{
        let response  = await fetch(process.env.REACT_APP_LOGIN_URL,{
                credentials:"include",
        });
        response = await response.json();
        if(response.result==="login again" || response.result === "unauthorized_user_jwt"){
            setError(true)
        }
    }

    useEffect(()=>{
     setlogin();
    },[])

    const [email,setEmail] = useState("");



    const openappaction = async ()=>{
        toast('Your request is in progress',
            {
                icon: '😄',
                style: {
                    borderRadius: '10px',
                    background: '#333',
                    color: '#fff',
                },
            }
        );
        let response = await fetch(process.env.REACT_APP_CHECKUSER_URL,{
            credentials:"include",
            mode: "cors",
        }
        )
        response = await response.json();
        if(response.result==="authorized_user"){
            navigate("/gen")
        }else{
            toast('No Active Subscriptions',
            {
                icon: '🥺',
                style: {
                    borderRadius: '10px',
                    background: '#333',
                    color: '#fff',
                },
            }
        );
        }
        
    }

    const handleclickdata = async () => {

        

        if (email !== "" && email.indexOf(".") !== -1 && email.indexOf("@") !== -1) {

            toast('Welcome to Aitubee.com',
            {
                icon: '😄',
                style: {
                    borderRadius: '10px',
                    background: '#333',
                    color: '#fff',
                },
            }
        );

            let response = await fetch(process.env.REACT_APP_LOGIN_URL, {
                method: 'POST',
                mode: "cors",
                credentials: "include",
                headers: {
                    "content-type": "application/json"
                },
                body: JSON.stringify({ email })
            })
            response = await response.json();
            // console.log(response.customer);

            if(response.customer.plan==="none"){
                navigate('/pricing');
            }
            if(response.customer.plan==="paid"){
                navigate('/gen');
            }
        }
        else {
            toast.error('Not a valid email',
                {
                    icon: '❌',
                    style: {
                        borderRadius: '10px',
                        background: '#333',
                        color: '#fff',
                    },
                }
            );

        }

        // console.log(email)
    }



    return (
        <div className="home">
            <div className="wrapper">
                <div className="textContainer">
                    <span className="small">Get Your Youtube Process</span>
                    <span className="title">AUTOMATED</span>

                </div>

                <div className="checkbox">
                    {
                        error ?
                    <div className="inputcontrollers">
                        <input className="input" type="email" placeholder="youremail@gmail.com" onChange={(e) => setEmail(e.target.value)} value={email} />
                        <input className="button" onClick={handleclickdata} type="button" value="Login" />
                    </div> 
                        :
                        <div className="usernameContainer">
                            {/* <div className="username">
                                Welcome {user}
                            </div> */}
                            <button className="button" onClick={openappaction}>Open App</button>
                        </div>
                    }
                </div>

                <div className="herosection">

                    <div className="lefttextcontainer">
                        <div className="bigtext"><h3>Get Unlimited</h3></div>
                        <div className="spancontainer">
                            <span>Video Titles</span>
                            <span>Video Descriptions</span>
                            <span>Video Tags</span>
                            <span>Summarize Video</span>
                        </div>
                    </div>

                    <div className="videoContainer">
                        <video controls src={video}></video>
                    </div>
                    {/* <div className="smalltext">
                            <span className="smalltext">AiTube will help you with AI generated </span>
                            <span className="titlemid"> Title , Description , Tags </span>
                        </div> */}
                </div>

            </div>
            <Toaster />
        </div>
    )
}

export default Home;
