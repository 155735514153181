import React from "react";
import { useState } from "react";
import thumbnail1 from "./thumbnail1.png";
import "./api.scss"
import toast, { Toaster } from 'react-hot-toast';
import Appnav from "../apitestnav/apitestnav";


const Checkapi=()=>{

    // https://youtu.be/MogeBn-gjOA

    const [title,setTitle] = useState([]);
    const [desc,setDesc] = useState("");
    const [tags,setTags]= useState([]);
    const[ytlink,setYtLink] = useState("");
    const [generate,setGenerate] = useState(false)

    // const response = {
    //     title: [
    //       'Unleash Your Photography Potential: Mastering Light with the Profoto A10 Flash!',
    //       'Embracing Your Dark SideRevolutionize Your Shots: The Pro Photo 810 Flash for Stunning Photography!',
    //       'Elevate Your Photography Game: Captivating Visuals with the Profoto A10 Flash!'
    //     ],
    //     des: "Welcome to today's video, where I'll introduce you to a game-changing piece of gear that has revolutionized my photography journey: the Profoto A10 Flash. With years of experience behind the lens, I've come to understand the pivotal role that lighting plays in capturing breathtaking images. The Profoto A10 Flash has become my go-to tool for achieving professional-grade results in various shooting conditions.Compact yet incredibly powerful, the Pro Photo 810 Flash boasts a maximum energy of 76 WATC and a versatile energy range of 9 stops. Whether you're running solo or working with a small crew, this flash is a game-changer, offering unparalleled portability without compromising on performance.In today's video, I'll delve into the practical aspects of using the Profoto A10 Flash in real-world scenarios. From setting up the flash on location to capturing dynamic shots in ideal conditions, you'll witness firsthand how this versatile tool enhances the quality of your photography.With its ability to rotate 360° and tilt 90°, the Profoto A10 Flash allows for creative freedom in positioning and directing light. Whether you're shooting portraits, landscapes, or action shots, this flash adapts to your needs, providing consistent, professional-level lighting every time.But investing in quality gear like the Profoto A10 Flash is not just about the upfront cost—it's about the long-term benefits it brings to your photography career. From attracting higher-paying clients to expanding your portfolio with stunning visuals, this flash is a worthy investment in your craft.So join me as I explore the capabilities of the Profoto A10 Flash and discover how it can elevate your photography game to new heights. Don't forget to like, share, and subscribe for more insightful videos, and let's embark on this journey together!",
    //     tags: [
    //         'Profoto A10 Flash','Pro Photo 810 Flash','Photography gear','Lighting equipment','Photography tips','Camera accessories','Flash photography','Professional photography','Creative lighting techniques','Portrait photography','Landscape photography','On-camera flash','Photography equipment review','Portable flash unit','Outdoor photography techniques'
    //     ]
    //   }

    //   useEffect(()=>{
    //     setTitle(response.title);
    //   setDesc(response.des);
    //   setTags(response.tags);
    //   setGenerate(true);
    //   },[])
      
      


    const sendData= async()=>{

        if(ytlink!=="" && ytlink.indexOf("=")===-1 && ytlink.indexOf("https://youtu.be")!==-1){

        toast('your request is in process',
        {
          icon: '😄',
          style: {
            borderRadius: '10px',
            background: '#333',
            color: '#fff',
          },
        }
        );


        let response= await fetch(process.env.REACT_APP_ANS_URL,{
            method:'post',
            body:JSON.stringify({ytlink}),
            credentials:"include",
            headers:{
                'content-type':"application/json"
            }
        })
        response=await response.json();
        // console.log(response.item)
        if(response.item!=="cannot_find_captions"){
            setTitle(response.item.title);
            setDesc(response.item.desc);
            setTags(response.item.tags);
            setGenerate(true);
        }else{
            setGenerate(false);
            // console.log("_______---------------inside else_----------")
            if(response.item==="cannot_find_captions"){
                toast.error("Sorry Couldn't process this link , please try again or later",
                {
                  icon: '🥺',
                  style: {
                    borderRadius: '10px',
                    background: '#333',
                    color: '#fff',
                  },
                }
                );   
            }
            if(response.result==="unAuthorized"){
                toast.error('Please buy a plan to access the app',
                {
                    icon: '😀',
                    style: {
                        borderRadius: '10px',
                        background: '#333',
                        color: '#fff',
                    },
                })
                // navigate('/pricing')

            }
            if(response.result==="unAuth"){
                
                toast.error('Login and then try again',
                {
                    icon: '❌',
                    style: {
                        borderRadius: '10px',
                        background: '#333',
                        color: '#fff',
                    },
                })
                // navigate('/')
            }
        }
        }else{
            toast.error('Not a valid Link! Please refer how to use block .',
            {
              icon: '🥺',
              style: {
                borderRadius: '10px',
                background: '#333',
                color: '#fff',
              },
            }
            );
        }
        // console.log(response);
    }


    const copytoclipboard = (item)=>{
        toast('Copied to clipboard',
        {
          icon: '😄',
          style: {
            borderRadius: '10px',
            background: '#333',
            color: '#fff',
          },
        }
        );
        if(item==="desc"){
            navigator.clipboard.writeText(`${desc}`)
        }
        else{
            navigator.clipboard.writeText(`${tags}`)
        }
    }
    


    return(
        <div className="parentdiv">
            <Appnav/>
        <div className="check">
            
            <div className="wrapper">
            {/* <div className="navText">
                <h2><Link to='/' className="link">AiTubee.com</Link></h2>
            </div> */}
            <div className="inputcontrollers">
            <input className="input" type="text" placeholder="Paste your link here" onChange={(e)=>setYtLink(e.target.value)} value={ytlink}/>
            <input className="button" type="button" onClick={sendData} value="Generate"/>
            </div>
            { generate &&
            <div className="generated">
            <div className="titlecontainer">    
            { 
                title?.map((item)=>
                <div className="titles">
                    <div className="imgContainer">
                    <img src ={thumbnail1} alt="thumbnail"/>
                    </div>
                    <div className="titlebox">
                    <span>{item}</span>
                    </div>
                    {/* <button className="copybtn">copy</button> */}
                </div>
                )
                
            }
            </div>
            <div className="desContainer">
                <div className="des">
                <h3>Description</h3> 
                <button className="copybtn" onClick={()=>copytoclipboard("desc")}>📑</button>
                </div>
                 <span>{desc}</span>
                
           </div>
           {
            <div className="tagsContainer">
                <div className="tag">
                    <h3>Tags</h3>
                    <button className="copybtn" onClick={()=>copytoclipboard("tags")}>📑</button>
                </div>
                <div className="tagsbox">
                 {tags?.map((item)=>
                <div className="span">{item+","}</div>
                )} 
                
                
                </div>
                </div>
           }
            </div>
           }
           </div>
           <Toaster/>
        </div>
        </div>
    )
}

export default Checkapi;