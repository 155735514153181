import "./nav.scss"
import {Link} from "react-router-dom";

const Navbar = ()=>{
    return(
        <div className="navbar">
            <div className="wrapper">
            <div className="left">
                <h2>AiTubee.com</h2>
            </div>
            <div className="right">
                <div className="links">
                <h3><Link to ='/summarize' className="link" >Summarize</Link></h3>
               <h3><Link to ="/howtouse" className="pricinglink">How to use </Link></h3>
                    <h3><Link to='/pricing' className="pricinglink" >Pricing</Link></h3>
                </div>
            </div>
            </div>
        </div>
    )
}

export default Navbar;