import "./pricing.scss";
import { Link} from "react-router-dom";
import toast, { Toaster } from 'react-hot-toast';

const Pricing = () => {

    const checkout = async (plan) => {
        
        let checkuser = await fetch(process.env.REACT_APP_CHECKUSER_URL,{
            credentials: 'include'
        }) 

        checkuser = await checkuser.json();
        console.log(checkuser.result , "from checkuser");

        if(checkuser.result==="authorized_user"){
            console.log("inside authorized part ", checkuser.result)
            toast('The active plan already exists',
            {
                icon: '😄',
                style: {
                    borderRadius: '10px',
                    background: '#333',
                    color: '#fff',
                },
            }
        );
        }


        if(checkuser.result==="unauthenticated_user" || checkuser.result === "unauthorized_user_jwt"){
            toast('please login first to buy subscription',
            {
                icon: '😄',
                style: {
                    borderRadius: '10px',
                    background: '#333',
                    color: '#fff',
                },
            }
        );
        }
        if(checkuser.result ==="unauthorized_user"){
            toast('Your request is in progress',
            {
                icon: '😄',
                style: {
                    borderRadius: '10px',
                    background: '#333',
                    color: '#fff',
                },
            }
        );
            console.log("inside unauthorized part ")
        let response = await fetch(process.env.REACT_APP_CHECKOUT_URL, {
            method: 'POST',
            headers: {
                "content-type": "application/json"
            },
            body:JSON.stringify({plan}),
            credentials: 'include'

        })

        response = await response.json();
        console.log(response.url)
        window.location.href = response.url
         }
    }





    return (
        <div className="pricing">
            <div className="wrapper">
                <div className="navText">
                    <h2><Link to='/' className="link">AiTubee.com</Link></h2>
                </div>
                <div className="textContainer">
                    <h2>Ready to automate your <span className="yt">YOUTUBE</span> process</h2>
                    <span>You're One step closer to make it happen </span>
                </div>
                <div className="plaincontainerwrapper">
                    <div className="plansContainer">
                        <div className="monthlyplan">
                            <div className="includes">

                                <h2>$19/Month</h2>
                                <ul>
                                    <li>Unlimited Video Titles</li>
                                    <li>Unlimted Video Description</li>
                                    <li>Unlimited Video Tags</li>
                                </ul>
                                <button onClick={()=>checkout("monthly_plan")}>Monthly Plan</button>
                            </div>
                        </div>
                        <div className="yearlyplan">
                            <div className="includes">
                                <h2>$15/Month</h2>
                                <ul>
                                    <li>Unlimited Video Titles</li>
                                    <li>Unlimted Video Description</li>
                                    <li>Unlimited Video Tags</li>
                                </ul>
                                <button onClick={()=>checkout("annual_plan")}>Yearly Plan</button>
                            </div>
                        </div>
                    </div>
                    <div className="bottomtext">
                        <span>Don't end up loving us in 7 Days ? <b>We Offer 100% refund Back .</b></span>
                    </div>
                </div>
            </div>
        <Toaster/>
        </div>
    )
}

export default Pricing;

