import "./apitestnav.scss";
import {useState,useEffect} from "react"
import {Link,useNavigate} from "react-router-dom";
import toast, { Toaster } from 'react-hot-toast';

const Appnav = ()=>{
    const [expdate,setExpdate] = useState("");
    const [email,setEmail] = useState("");
    const navigate = useNavigate();

    const checkUser = async()=>{
        let response  = await fetch(process.env.REACT_APP_CHECKUSER_URL,{
            credentials:"include"
        });
        response= await response.json();
        // console.log(response)
        if(response.result==="authorized_user"){
            let date = response.data.expdate;
            let index = date.indexOf("T")
            setExpdate(date.substring(0,index));
            let em = response.data.email;
            let i = em.indexOf("@");
            setEmail(em.substring(0,i));
        }

    }

    useEffect(()=>{
        checkUser();
    },[])

    const handleLogout = async ()=>{
        let response  = await fetch(process.env.REACT_APP_LOGOUT_URL,{
            credentials:"include"
        })
        response = await response.json();
        if(response.result==="logout_successfully"){
            navigate('/');
        }
    }

    const handleCancelsub = async()=>{
        toast('your request is in process',
        {
          icon: '😄',
          style: {
            borderRadius: '10px',
            background: '#333',
            color: '#fff',
          },
        }
        );
        let response = await fetch(process.env.REACT_APP_CANCEL_URL,{
            credentials:"include"
        })

        response = await response.json();
        if(response.result==="canceled"){
            navigate('/')
        }
    }





    return(
        <div className="apinavbar">
            <div className="wrapper">
                <div className="leftitems">
                <h2><Link to='/' className="link homelink">Aitubee.com</Link></h2>
                <h2>🟢Active till {expdate}</h2>
                <button onClick={handleCancelsub}>Cancel Subscription</button>
                </div>
                <div className="rightitems">
                <h2><Link to ='/summarize' className="link summarize">Summarize</Link></h2>
                {/* <h2>Upgrade</h2> */}
                <h2>{email}</h2>
                <button onClick={handleLogout}>Logout</button>
                </div>
               
            </div>
            <Toaster/>
        </div>
    )
}

export default Appnav;
